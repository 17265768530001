/* You can add global styles to this file, and also import other style files */
@import 'variables';

$insta-blue: #30619F;

* {
  font-family: 'Roboto', sans-serif;
  --mat-option-selected-state-label-text-color: #30619F !important;
  --mdc-checkbox-selected-focus-state-layer-color: #30619F !important;
  --mdc-checkbox-selected-hover-state-layer-color: #30619F !important;
  --mdc-checkbox-selected-pressed-state-layer-color: #30619F !important;
}

body {
  background-color: #EEEEEE;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.bg-insta-blue {
  background-color: $insta-blue;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0 8px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $insta-blue;
  color: white;

  .ng-value-icon {
    color: white;

    &:hover {
      background-color: rgba(97, 158, 227, 0.5);
    }

  }

}

/** Change mat-input value color when field is disabled */
.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0,0,0,0.75) !important;
}

/** Change background color in materials input fields to white */
.mdc-text-field {
  background-color: white;
}

/** Remove bottom-margin from primeng split button dropdown menu */
.p-tieredmenu-root-list {
  margin-bottom: 0 !important;
}

/** Remove border-radius from primeng split button right side */
.p-splitbutton-defaultbutton {
  border-radius: 5px 0 0 5px !important;
}

/** Remove border-radius from primeng split button left side on dropdown button */
.p-splitbutton-menubutton {
  border-radius: 0 5px 5px 0 !important;
}

.color-insta-blue {
  color: $insta-blue;
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-muted {
  color: #717C99 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  max-height: 5px !important;
}

.p-tieredmenu-overlay {
  left: unset !important;
  right: 0 !important;
}

.mat-datepicker-content-container {
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

.mat-calendar-body-selected {
  background-color: $insta-blue !important;
  color: white;
  border: unset;
}

.mat-calendar-table-header {
  tr {
    th {
      span {
        font-weight: lighter;
        font-size: 0.9em;
      }
    }
  };
}

.btn-outline-secondary {
  &:hover,&:active,&:focus {
    color: #6c757d !important;
    background-color: rgba(0,0,0,0.05) !important;
  }
}

.ng-select-container {
  border: unset !important;
}

.p-button-label {
  transition: unset !important;
}

.p-button {
  font-size: 0.95em;
  border-radius: 5px;
}

.p-tooltip {
  z-index: 100000 !important;
}

.p-tooltip .p-tooltip-text {
  background: rgba(70, 70, 70, 0.95);
}

.p-buttonset {
  .p-button {
    border-radius: 0px;

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }

  }
}

.ng-select.ng-select-disabled .ng-select-container:after {
  background-image: unset !important;
}

.p-button-squared {
  border-radius: 0 !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  font-size: 0.95em;
  padding: 10px 15px;
}

.mat-warn-color {
  color: #F44336;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.mdc-radio {

  &:hover,&:active,&:focus {
    color: #6c757d !important;
    background-color: rgba(0,0,0,0.05) !important;
  }

  .mdc-radio__background {
    .mdc-radio__inner-circle, .mdc-radio__outer-circle {
      border-color: #30619F !important;

      &:hover,&:active,&:focus {
        color: #6c757d !important;
        background-color: rgba(0,0,0,0.05) !important;
      }

    }
  }
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.namecode {
  background-color: $insta-blue;
  font-size: 1rem;
  color: #fff;
  padding: 0.5rem;
  text-transform: uppercase;
  padding: unset;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}

.p-button-secondary {
  color: #4f4f4f !important;
  background: transparent !important;

  &:hover {
    background: rgba(169, 169, 169, 0.2) !important;
  }

}
::ng-deep {
  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}

.auditmodal {
  width: 70vw;
}

.prewrap {
  white-space: pre-wrap;
}

@media screen and (max-width: 40em) {
  .auditmodal {
      width: 100vw;
  }
}

@media print {

  html, body {
    background-color: white;
  }

  .mat-drawer-container {
    background-color: white !important;
    overflow: visible !important;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .mat-drawer-content {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .p-dialog-header-close-icon {
    display: none !important;
  }
}

// mat-select modal fix
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

bs-datepicker-container {
  z-index: 20000 !important;
}

.mat-mdc-checkbox {

  &:hover,&:active,&:focus {
    color: #6c757d !important;
  }

  label {
    margin-bottom: 0;
  }
}

.mat-mdc-focus-indicator {
  &:focus, &:active, &:hover, &.cdk-program-focused {
    background-color: rgba(48, 97, 159, 0.05) !important;
    color: rgba(48, 97, 159, 0.05) !important;
  }
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #30619F !important;
  background-color: #30619F !important;
}

@media only screen and (max-width: 768px) {
  .secondary-bar {
    margin-left: unset !important;
  }

  .card-mobile-form {
    margin-top: unset !important;
    margin-bottom: unset !important;
    box-shadow: none !important;
  }

  .card-body-mobile-form {
    padding: unset !important;
  }

  .impact-sidenav-container-mobile-form {
    margin-top: unset !important;
  }

  .mobile-form {
    margin: 0px !important;
    padding: unset !important;
  }

  .position-fixed-mobile-form {
    position: unset !important;
    width: 100% !important;
  }

  .fixed-top-mobile-form {
    position: relative !important;
  }
}

::ng-deep {
  .mat-form-field-disabled {
    mat-label, .mat-input-element {
      color: rgba(0,0,0,0.85) !important;
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }

}

@media only screen and (max-width: 768px) {
  .sticky-page-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999999999;
  }

  /** position sticky doesn't work if there is overflow property set in the parent element */
  ::ng-deep {
    .mat-drawer-content, .mat-drawer-container {
      overflow: unset !important;
    }
  }
}
